<template>
  <b-card
    v-if="data"
    class="card-transaction"
    no-body
  >
    <router-link to="/people">
      <b-card-header
        header-bg-variant="warning bg-darken-4"
        class="header pointer"
      >
        <b-card-title class="title">
          <b-link class="text-props">
            <feather-icon
              size="22"
              icon="UsersIcon"
              class="text-white"
            />
          </b-link>
          People
        </b-card-title>
      </b-card-header>
    </router-link>

    <b-card-body>
      <div class="transaction-item">
        <b-media no-body>
          <router-link to="/people">
            <b-media-body>
              <h6 class="transaction-header">
                <span class="text-props">
                  <span>{{ data.all }}</span>
                </span>
                People
              </h6>

            </b-media-body>
          </router-link>
        </b-media>
      </div>

      <div class="transaction-item">
        <b-media no-body>
          <router-link
            :to="{name : 'user-list', query: { q: 'is:admin' }}"
            link-class="text-props text-primary"
          >
            <b-media-aside>

              <feather-icon
                size="18"
                icon="KeyIcon"
                class="text-primary"
              />

            </b-media-aside>
          </router-link>
          <b-media-body>
            <h6 class="transaction-title">
              <router-link
                :to="{name : 'user-list', query: { q: 'is:admin' }}"
                link-class="text-props text-primary"
              >
                <span>{{ data.admins }}</span>
              </router-link>
              with Admin access
            </h6>

          </b-media-body>
        </b-media>
      </div>

      <div class="transaction-item">
        <b-media no-body>
          <router-link
            :to="{name : 'user-list', query: { q: 'is:manager' }}"
            link-class="text-props text-primary"
          >
            <b-media-aside>

              <feather-icon
                size="18"
                icon="UsersIcon"
                class="text-primary"
              />

            </b-media-aside>
          </router-link>
          <b-media-body>
            <h6 class="transaction-title">
              <router-link
                :to="{name : 'user-list', query: { q: 'is:manager' }}"
                link-class="text-props text-primary"
              >
                <span>{{ data.managers }}</span>
              </router-link>
              with Manager access
            </h6>

          </b-media-body>
        </b-media>
      </div>

      <div class="transaction-item">
        <b-media no-body>
          <router-link
            :to="{name : 'user-list', query: { q: 'is:user' }}"
            link-class="text-props text-primary"
          >
            <b-media-aside>

              <feather-icon
                size="18"
                icon="UserIcon"
                class="text-primary"
              />

            </b-media-aside>
          </router-link>
          <b-media-body>
            <h6 class="transaction-title">
              <router-link
                :to="{name : 'user-list', query: { q: 'is:user' }}"
                link-class="text-props text-primary"
              >
                <span>{{ data.users }}</span>
              </router-link>
              with User access
            </h6>

          </b-media-body>
        </b-media>
      </div>
      <hr>

      <div class="transaction-item">
        <b-media no-body>
          <router-link
            :to="{name : 'user-list', query: { q: 'has:noRole' }}"
            link-class="text-props text-primary"
          >
            <b-media-aside>

              <feather-icon
                v-if="data.noRole > 0"
                size="18"
                icon="AlertTriangleIcon"
                class="text-warning"
              />
              <feather-icon
                v-else
                size="18"
                icon="CheckCircleIcon"
                class="text-success"
              />

            </b-media-aside>
          </router-link>

          <b-media-body>
            <h6 class="transaction-title">
              <router-link
                :to="{name : 'user-list', query: { q: 'has:noRole' }}"
                link-class="text-props text-primary"
              >
                <span>{{ data.noRole }}</span>
              </router-link>
              with no Role assigned
            </h6>

          </b-media-body>
        </b-media>
      </div>

      <div class="transaction-item">
        <b-media no-body>
          <router-link
            :to="{name : 'user-list', query: { q: 'has:noAccess' }}"
            link-class="text-props text-primary"
          >
            <b-media-aside>

              <feather-icon
                v-if="data.noAccess > 0"
                size="18"
                icon="AlertTriangleIcon"
                class="text-warning"
              />
              <feather-icon
                v-else
                size="18"
                icon="CheckCircleIcon"
                class="text-success"
              />

            </b-media-aside>
          </router-link>
          <b-media-body>
            <h6 class="transaction-title">
              <router-link
                :to="{name : 'user-list', query: { q: 'has:noAccess' }}"
                link-class="text-props text-primary"
              >
                <span>{{ data.noAccess }}</span>
              </router-link>
              with no access
            </h6>

          </b-media-body>
        </b-media>
      </div>

    </b-card-body>
  </b-card>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BLink,
  },
  setup() {
    const { wrench } = $themeConfig.icons
    return {
      wrench,
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style scoped>
.text-props{
    color: #193a9a;
}
h6,span{
    font-weight: bold;
}
.header{
  margin-bottom: 10px;
}
.title{
  color: white;
}
.logo{
  height: 22px;
  width: 22px;
}
.transaction-title{
  color: black;
  font-weight: normal;
}

.transaction-header{
  color: black;
}
.pointer {
  cursor: pointer;
}
</style>
